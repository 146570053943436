@import '/src//styles/config.scss';

.item {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    width: 100%;
}

.change {
    font-size: 14px;
    color: rgb(94, 94, 94);
    margin-right: 16px;
    cursor: pointer;
}

.cityName {
    font-size: 16px;
    color: rgb(77, 81, 88);
}