@import '~antd/dist/antd.css';


/*
=============================
Variables
=============================
*/

/*
=============================
Breakpoints
=============================
*/

/*
=============================
Colors
=============================
*/

$color-font-color: #333333;
$color-background: #f2f4f6;
$color-primary: #0084cc;
$color-gmail: #db4742;
$color-orange: #ff8413;
$color-gray1: #7d7d7d;
$color-gray2: #5f5f5f;
$color-gray3: #e3e3e3;
$color-photo-background: #c4c4c4;
$color-blue: #009cf0;
$color-light-blue: #d1edfc;
$color-dark-blue: #004870;
$color-pink: #fff1f1;
$color-green: #009a34;

$color-lang-advanced: $color-green;
$color-lang-intermediate: #fbb605;
$color-lang-begginer: #c60101;

/*
=============================
functions
=============================
*/

/*
=============================
Mixins
=============================
*/

.cart {
    background: #ffffff;
    box-shadow: 0 0 4px rgba(54, 54, 54, 0.25);
    border-radius: 6px;
}

.formRow {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: 30px;
}

.formCol {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
}
.formCol8 {
    @extend .formCol;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.6666666%;
    max-width: 66.6666666%;
}

.formSection {
    margin-top: 40px;
}

.formSectionLabel {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $color-font-color;
    margin-bottom: 38px;
}
