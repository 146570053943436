@import '../../../styles/config';

.footer {
    padding: 16px 20px;
    color: #fff;
}

.exampleLink {
    color: #fff;
    text-decoration: none;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
}

.borderline {
    border-bottom: 1px solid rgba(128, 128, 128, 0.274);
    // width: 100%;
}

.title {
    word-break: break-all;
    font-size: 15px;
    font-weight: bolder;
    margin-bottom: 10px;
}
.description {
    word-break: break-all;
    line-height: 1.2;
}
.change {
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}
.item {
    display: flex;
    justify-content: space-between;
    
}
.icon {
    margin-left: 15px;
    color:red;
    cursor: pointer;
    transition: all 0.3s ease-in;
    &:hover {
        transform:scale(1.3)
    }
}
.modal {
    width: 1000px;
    height: 500px;
}
.titleModal {
    font-size: 14px;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 0px;
}

.photoMainWrapper{ 
    height: 104px; 
    display: flex;
}
// .prevPhotoWrapper {
//     display: flex;

// }
.photoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0;
    border: 1px solid rgb(211, 211, 211);
    border-radius: 2px;
    padding: 8px;
    position: relative;
    &:hover .photo{
        visibility: visible
    }
    &:hover .photo{
        background: rgba(0,0,0,0.7);
        opacity: 0.9
    }
    &:hover .iconsInsideWrapper{
        visibility: visible;
        // background-color: orange;
        opacity: 1;
    }

}
.photo {
    transition: all 0.3s ease-in-out;
    display: block;
    object-fit: contain;
    width: 86px;
    height: 86px;
    opacity: 0.8;
    
    
}
.iconsInsideWrapper {
    transition: all 0.3s ease-in-out;
    position: absolute;
    z-index: 5;
    display: flex;
    gap: 12px;
    visibility: hidden;
    opacity: 0;
}
.oldImgInsideModal {
    width: 100%;
}