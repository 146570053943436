.addButton {
    padding: 8px 10px;
    border: 1px solid #c4c4c4;
    display: flex;
    margin-right: 10px;
    border-radius: 10px;
    background-color: #fff;
    height: 50px;
    align-items: center;
    cursor: pointer;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin-bottom: 10px;
    // background-color: #f7f7f7;
}

.container {
    border: 1px solid #c4c4c4;

    background-color: #fff;

}

.inputBlock {
    background-color: #fff;
    padding: 0 10px;
}

.textArea {
    width: 100%;
    border: none;
    resize: none;
    min-height: 100px;
    max-height: 250px;
    border: 1px solid #c3c3c3;
    padding: 8px;
    border-radius: 10px;
    margin-top: 8px;
}

.imgBlock {
    display: flex;
    align-items: flex-end;
    margin-right: 15px;
}

.imgHolder {
    margin-top: 10px;
    display: flex;
}

.delBlock {
    position: absolute;
    top: 10px;
    right: 10px;
}

.textBlock {
    position: relative;
    padding-right: 50px;
}

.title {
    font-weight: 800;
    font-size: 16px;
    margin: 0 0 5px 5px;
}

// TEAXT AREA
.strong {
    font-weight: 700;
}
.quote {
    font-style: italic;
}

// SELCTOR
.selector {
    margin: 0 4px;
    box-shadow: 0  0 7px rgba(0,0,0,0.2);
}

// HEADER
.blockHeader {
    display: flex;
    margin-top: 5px;
}
.headerButton {
    padding: 5px 10px;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    margin: 0 4px;
    cursor: pointer;
    box-shadow: 0  0 7px rgba(0,0,0,0.2);
}
.headerBtnActive {
    background-color: rgb(224, 234, 241);
    box-shadow: none;
}

//ADD LINK MODAL
.addLinkModal {
    padding-top: 25px;
}

.inputHolder {
    margin-top: 35px;
}