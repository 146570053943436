@import '../../../styles/config';

.list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 50px;
}

.titles {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .titleBlock {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        h5 {
            margin: 0;
        }
    }
}

.emptyText {
    font-size: 18px;
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
