.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.pagination {
    display: flex;
    width: 100%;
    justify-content: center;
}