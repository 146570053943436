.wrapper {
    margin: 30px 0;
}

.title {
    display: block;
    font-size: 14px;
    color: rgb(119, 119, 119);
    margin-bottom: 10px;
}

.list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & li {
        width: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        border-radius: 10px;

        &:not(:last-child) {
            margin-right: 20px;
        }
    }
}

.imgWrapper {
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 10px;

    background-color: rgba(0, 0, 0, 0.329);

    &:hover {
        cursor: pointer;

        & .itemMask {
            opacity: 1;
            visibility: visible;
        }
    }
}

.img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-bottom: 10px;
}

.check {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 5px;
}

.itemMask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: inherit;

    background-color: rgba(0, 0, 0, 0.329);
    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0;
    visibility: hidden;

    transition: all 0.2s linear;
}

.load {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: inherit;

    background-color: rgba(0, 0, 0, 0.329);
    display: flex;
    justify-content: center;
    align-items: center;
}
