@import '../../../styles/config';

.footer {
    padding: 16px 20px;
    color: #fff;
}
.logoWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}
.logo {
    width: 70%;
}

.exampleLink {
    color: #fff;
    text-decoration: none;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
}