@import '../../../styles/config';

.footer {
    padding: 16px 20px;
    color: #fff;
}

.exampleLink {
    color: #fff;
    text-decoration: none;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
}


.title {
    font-weight: 800;
    font-size: 16px;
    margin: 0 0 5px 5px;
}