@import '../../../styles/config';

.footer {
    padding: 16px 20px;
    color: #fff;
}

.exampleLink {
    color: #fff;
    text-decoration: none;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
}

.borderline {
    border-bottom: 1px solid rgba(128, 128, 128, 0.274);
    // width: 100%;
}
.itemsWrapper {
    margin-bottom: 15px;
}
.textWrapper {

}

