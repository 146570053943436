.change {
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}
.icon {
    margin-left: 15px;
    fill:red;
    cursor: pointer;
    transition: all 0.3s ease-in;
    &:hover {
        transform:scale(1.3)
    }
}

.btn {
    margin-right: 15px;
    cursor: pointer;
}