@import '../../../styles/config';

.footer {
    padding: 16px 20px;
    color: #fff;
}

.exampleLink {
    color: #fff;
    text-decoration: none;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
}

.container {
    border: 1px solid #c4c4c4;

    background-color: #fff;
}
.textBlock {
    position: relative;
    padding-right: 50px;
}
.inputBlock {
    background-color: #fff;
    padding: 0 10px;
}
.title {
    font-weight: 800;
    font-size: 16px;
    margin: 0 0 5px 5px;
}
.carendarsWrapper {
    display: flex;
    gap: 30px;
}
.calendar {
    width: 300px;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
}
.priceWrapper {
    margin-bottom: 15px;
}
.selectWrapper {
    margin-bottom: 15px;
}
.seanceBlock {
    padding-bottom: 15px;
}
.seanceInner {
    display: flex;
    justify-content: space-between;
    gap: 30px;
}
.seanceRightItem {
    width: 80%;
}
.seanceItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
    border-bottom: 1px solid rgba(124, 124, 124, 0.452);
}
.seanceSpan {
    font-weight: 600;
}
.closeIcon {
    color: rgb(255, 89, 89);
    transform: scale(1.5);

    &:hover {
        transform: scale(2);
        color: rgb(255, 57, 57);
    }
}
.dateRange {
    margin-bottom: 15px;
}
.btnSeanse {
    margin-top: 10px;
}
.inputFactsWrapper {
    gap: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}
.customGalery {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    // flex-wrap: wrap;
}

.photoMainWrapper {
    height: 104px;
    display: flex;

    // flex-wrap: nowrap;
}
// .prevPhotoWrapper {
//     display: flex;

// }
.photoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0;
    border: 1px solid rgb(211, 211, 211);
    border-radius: 2px;
    padding: 8px;
    position: relative;
    &:hover .photo {
        visibility: visible;
    }
    &:hover .photo {
        background: rgba(0, 0, 0, 0.7);
        opacity: 0.9;
    }
    &:hover .iconsInsideWrapper {
        visibility: visible;
        // background-color: orange;
        opacity: 1;
    }
}
.photo {
    transition: all 0.3s ease-in-out;
    display: block;
    object-fit: contain;
    width: 86px;
    height: 86px;
    opacity: 0.8;
}
.iconsInsideWrapper {
    transition: all 0.3s ease-in-out;
    position: absolute;
    z-index: 5;
    display: flex;
    gap: 12px;
    visibility: hidden;
    opacity: 0;
}
.oldImgInsideModal {
    width: 100%;
}
.btnWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.linksWrapper {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
}

.photoKassyBlock {
    margin-top: 50px;

    display: flex;
    flex-direction: column;

    p {
        font-weight: 800;
        margin-bottom: 10px;
    }

    .photoKassy {
        width: 50%;
        height: auto;
    }

    button {
        width: 50%;
    }
}
