.root {
    padding: 40px 10px;
}

.itemHolder {
    margin-bottom: 40px;
}

.coords {
    display: flex;
    justify-content: space-between;


}

.textField {
    width: 48%;
}