@import '../../../styles/config';

.root {
}

.outLabel {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 7px;
}

.base {
    border: 2px solid #e3e3e3;
    border-radius: 6px;
    position: relative;
    background: #fff;
}

.label {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px;
    line-height: 16px;
    color: #8b8b8b;
    transform: translate(19px, 14px);
    z-index: 1;
    pointer-events: none;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        font-size 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        line-height 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input {
    width: 100%;
    height: 44px;
    border: none;
    background: transparent;
    padding: 16px 20px;
    font-family: Roboto;
    line-height: 140%;
}

input[type='time']::-webkit-datetime-edit-hour-field,
input[type='time']::-webkit-datetime-edit-minute-field,
input[type='time']::-webkit-datetime-edit-text {
    opacity: 0;
}

.notch {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    line-height: 14px;
    transform: translate(10px, -9px);
    background: #fff;
    max-width: 0.01px;
    box-sizing: border-box;
}
.notchContainer {
    visibility: hidden;
    padding: 1px 6px;
}

.focus,
.filled {
    .base {
        border-color: $color-primary;
    }

    .label {
        font-size: 12px;
        line-height: 14px;
        color: $color-font-color;
        transform: translate(16px, -8px);
    }

    .notch {
        max-width: none;
    }

    input[type='time']::-webkit-datetime-edit-hour-field,
    input[type='time']::-webkit-datetime-edit-minute-field,
    input[type='time']::-webkit-datetime-edit-text {
        opacity: 1;
    }
}

.focus {
    input[type='time']::-webkit-datetime-edit-hour-field,
    input[type='time']::-webkit-datetime-edit-minute-field,
    input[type='time']::-webkit-datetime-edit-text {
        opacity: 1;
    }
}

.helperText {
    font-size: 12px;
    line-height: 14px;
    padding: 8px 18px;
}

.error {
    .base {
        border-color: #ff6767;
    }
    .helperText {
        color: #ff6767;
    }
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}
