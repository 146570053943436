@import '../../../styles/config';

.button {
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border: none;
    padding: 1px 60px;

    &:disabled {
        pointer-events: none;
        background: #94959a;
    }
}

.defaultColor {
    background: #9a9a9a;
    color: #fff;
}

.primaryColor {
    background: $color-primary;
    color: #fff;

    &:active {
    }
}

.secondaryColor {
}

.lightColor {
    background: #fff;
    border: 2px solid $color-primary;
    color: $color-primary;
}

$contained-border-radius: 6px;
.containedVariant {
    border-radius: $contained-border-radius;
    position: relative;

    &:before,
    &:after {
        content: '';
        position: absolute;
        border-radius: $contained-border-radius;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.25s ease;
    }
    &:before {
        background: linear-gradient(267.32deg, #4ebefc 0%, $color-primary 100%);
        z-index: 1;
    }

    &:hover:before {
        opacity: 1;
    }

    &:after {
        background: #1aa5f1;
        z-index: 2;
    }
    &:active:after {
        opacity: 1;
    }
}

.lightColor.containedVariant {
    transition: color 0.25s ease;

    &:before,
    &:after {
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
    }

    &:hover {
        color: #fff;
    }
}

.linkVariant {
    background: none;
    color: $color-font-color;
    text-decoration: underline;
    padding: 0;
    font-weight: normal;
}

.contain {
    position: relative;
    z-index: 3;
}
