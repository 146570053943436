@import '../../../styles/config';

.footer {
    padding: 16px 20px;
    color: #fff;
}

.exampleLink {
    color: #fff;
    text-decoration: none;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
}
.userInfo {
     display: flex;
     flex-direction: column;
     margin-right: 20px;
}
.userInfoText {
    color: $color-pink;
    text-align: right;
    color: #fff1f1;
    font-size: 16px;
    line-height: 20px;
}