@import '../../../styles/config';

.title {
    font-weight: 800;
    font-size: 16px;
    margin: 0 0 5px 5px;
}
.inputContainer {
    margin-bottom: 15px;
    width: 100%;
}