@import '../../../styles/config';

.footer {
    padding: 16px 20px;
    color: #fff;
}

.exampleLink {
    color: #fff;
    text-decoration: none;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
}


.title {
    font-weight: 800;
    font-size: 16px;
    margin: 0 0 5px 5px;
}

.customGalery {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}


.photoMainWrapper{ 
    height: 104px; 
    display: flex;
}

.photoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0;
    border: 1px solid rgb(211, 211, 211);
    border-radius: 2px;
    padding: 8px;
    position: relative;
    &:hover .photo{
        visibility: visible
    }
    &:hover .photo{
        background: rgba(0,0,0,0.7);
        opacity: 0.9
    }
    &:hover .iconsInsideWrapper{
        visibility: visible;
        // background-color: orange;
        opacity: 1;
    }

}
.photo {
    transition: all 0.3s ease-in-out;
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    
    
}
.iconsInsideWrapper {
    transition: all 0.3s ease-in-out;
    position: absolute;
    z-index: 5;
    display: flex;
    gap: 12px;
    visibility: hidden;
    opacity: 0;
}
.oldImgInsideModal {
    width: 100%;
}
.btnWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.linksWrapper {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;

}

.wrapSocial {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
    padding-top: 10px;
}
.socName {
    width: 250px;
}
.label {
    font-weight: 500;
}
.nameWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.image {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 15px;
    // object-fit: fill;
    img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        // height: auto;
    }
}