@import '../../../styles/config';

.cat {
    border: 1px black solid;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .row {
        width: 50%;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:first-child {
            border-right: 1px black solid;
        }

        p {
            font-size: 16px;
        }
    }
}
