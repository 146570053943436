.loader {
    width: 25px;
    height: 25px;

    border-top: 4px solid rgb(125, 165, 250);
    border-right: 4px solid rgb(125, 165, 250);
    border-left: 4px solid rgb(125, 165, 250);
    border-bottom: 4px solid transparent;
    border-radius: 100%;

    animation: load 1s linear infinite;
}

@keyframes load {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
