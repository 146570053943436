.root {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;

    background-color: rgba(80, 80, 80, 0.514);

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.content {
    width: 100%;
    height: 100%;
    max-height: 900px;
    overflow: hidden;

    position: relative;
    max-width: 1380px;
    padding: 40px 0;
    background-color: #f9f8f6;

    border-radius: 20px;

    &.mini {
        width: 100%;
        max-width: 800px !important;
        height: 100%;
        max-height: 700px !important;
    }
}

.wrapper {
    width: 100%;
    height: 100%;
    max-height: 900px;
    overflow-y: auto;
    padding: 0 30px;

    // &.mini {
    //     width: 100%;
    //     max-width: 800px !important;
    //     height: 100%;
    //     max-height: 700px !important;
    // }
}

.close {
    position: absolute;
    top: 20px;
    right: 15px;
    z-index: inherit;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: #000;

        transform: rotate(45deg) translateY(1px);
    }

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: #000;

        transform: rotate(315deg) translateY(-1px);
    }
}

.title {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;

    font-size: 18px;
    font-weight: bold;
}

.mainImage {
    width: 500px;
    height: 400px;
    overflow: hidden;
    margin: 20px auto;

    & img {
        width: 100%;
        height: 100%;
    }
}

.actions {
    margin: 20px 0;
    padding: 0;
    list-style: none;

    // width: 20%;

    & li {
        margin: 0 auto;
        display: flex;
        align-items: center;

        & .btnRotate {
            margin-left: 30px;
            display: flex;
            align-items: center;
            & button {
                cursor: pointer;

                &:first-child {
                    margin-right: 10px;
                }
            }
        }

        & input {
            margin-left: 30px;
        }

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}

.send {
    width: 200px;
    height: 40px;
    padding: 10px 30px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 9px 0px rgba(34, 60, 80, 0.21);
    -moz-box-shadow: 0px 0px 9px 0px rgba(34, 60, 80, 0.21);
    box-shadow: 0px 0px 9px 0px rgba(34, 60, 80, 0.21);

    &:hover {
        cursor: pointer;
        -webkit-box-shadow: 0px 0px 9px 0px rgba(34, 60, 80, 0.21) inset;
        -moz-box-shadow: 0px 0px 9px 0px rgba(34, 60, 80, 0.21) inset;
        box-shadow: 0px 0px 9px 0px rgba(34, 60, 80, 0.21) inset;
    }

    &:disabled {
        background-color: rgba(0, 0, 0, 0.288);
        cursor: not-allowed;
        padding: 0 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkList {
    padding: 20px;
}

.checkListItem {
    padding: 10px 0;
    &:not(:last-child) {
        border-bottom: 1px solid #ccc;
    }
}

.sizeTitle {
    margin-left: 20px;
    font-weight: 500;
}

.items {
    display: flex;
    align-items: center;
}

.editor {
    width: 100%;
    margin: 20px 0;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;

    -webkit-box-shadow: 0px 0px 9px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 9px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 9px 1px rgba(34, 60, 80, 0.2);
}

.simple {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btnCenter {
    width: 100%;
    display: flex;
    justify-content: center;

    & button:disabled {
        color: rgba(0, 0, 0, 0.253);
        background-color: rgba(0, 0, 0, 0.068);
        pointer-events: none;
        box-shadow: none;
    }
}
