.wrapper {
    margin: 30px 0;
}

.title {
    font-weight: 800;
    font-size: 16px;
    margin-bottom: 10px;
}

.input {
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
}

.load {
    display: inline-block;
    padding: 4px 15px;
    font-size: 14px;
    color: rgb(104, 104, 104);
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    cursor: pointer;

    transition: all 0.2s linear;

    &:hover {
        color: #40a9ff;
        border-color: #40a9ff;
    }
}
