@import '../../../styles/config';

.footer {
    padding: 16px 20px;
    color: #fff;
}

.exampleLink {
    color: #fff;
    text-decoration: none;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
}

.borderline {
    border-bottom: 1px solid rgba(128, 128, 128, 0.274);
    // width: 100%;
}
.itemsWrapper {
    
}
.textWrapper {

}
.title {
    word-break: break-all;
    font-size: 15px;
    font-weight: bolder;
    margin-bottom: 10px;
}
.description {
    word-break: break-all;
    line-height: 1.2;
}
.change {
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}
.item {
    display: flex;
    justify-content: space-between;
    
}
.icon {
    margin-left: 15px;
    color:red;
    cursor: pointer;
    transition: all 0.3s ease-in;
    &:hover {
        transform:scale(1.3)
    }
}
.modal {
    width: 1000px;
    height: 500px;
}
.titleModal {
    font-size: 14px;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 0px;
}