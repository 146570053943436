@import '/src/styles/config.scss';
.root {
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}
.tabs {
    display: flex;

    margin-top: 25px;
    margin-bottom: 45px;
}
.tabItem {
    font-size: 28px;
    margin-right: 35px;
    cursor: pointer;
    opacity: 0.3;
}

.isActive {
    opacity: 1;
    text-decoration: underline;
}

.filter {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.filterPart {
    padding: 0 20px;
}
.filterTable {
    width: 100%;

    tr td {
        width: 30%;
        min-width: 300px;
    }
}
.filterString {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    .filterLabel {
        width: 140px;
        margin-right: 10px;
    }
}

.datePicker {
    height: 32px;
    width: 220px;
}

.textField {
    input {
        height: 32px;
        border-radius: 4px;
        width: 250px;
    }
}

.selector {
    min-height: 40px;
    border-radius: 4px;
    width: 250px;
    max-width: 250px;
}

.tableWrapper {
    position: relative;
}

.table {
    margin-top: 50px;
    width: 100%;
    min-width: 100%;
    border-collapse: separate;
    height: 600px;
    overflow-y: auto;
    position: relative;

    table {
        width: 100%;
        position: relative;
    }

    table thead tr th {
        position: sticky;
        top: 0;

        &:nth-child(1) {
            width: 80px;
        }
        &:nth-child(2) {
            width: 200px;
        }
        &:nth-child(3) {
            width: 100px;
        }
        &:nth-child(4) {
            width: 200px;
        }
        &:nth-child(5) {
            width: 400px;
        }
        &:nth-child(5) {
            width: 200px;
        }
        &:nth-child(6) {
            width: 200px;
        }
        &:nth-child(7) {
            width: 100px;
        }
    }

    table th {
        vertical-align: middle;
        padding: 10px 20px;
        text-align: center;
        background-color: rgb(248, 248, 248);
    }
    table td {
        vertical-align: middle;
        padding: 10px 20px;
        text-align: center;
        background-color: #fff;
        border: 1px solid rgb(202, 202, 202);
    }
}

.modalField {
    display: flex;
    flex-direction: column;
    label {
        margin-left: 10px;
        margin-bottom: 16px;
    }
}
