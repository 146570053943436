.customGalery {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    // flex-wrap: wrap;
}
.title {
    font-weight: 800;
    font-size: 16px;
    margin: 0 0 5px 5px;
}


.photoMainWrapper{ 
    height: 150px; 
    display: flex;
    max-width: 500px;
    min-width: 150px;
    overflow-y: auto;
    margin-right: 25px;
    
    // flex-wrap: nowrap;

}
// .prevPhotoWrapper {
//     display: flex;

// }
.photoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0;
    border: 1px solid rgb(211, 211, 211);
    border-radius: 2px;
    padding: 8px;
    position: relative;
    &:hover .photo{
        visibility: visible
    }
    &:hover .photo{
        background: rgba(0,0,0,0.7);
        opacity: 0.9
    }
    &:hover .iconsInsideWrapper{
        visibility: visible;
        // background-color: orange;
        opacity: 1;
    }

}
.photo {
    transition: all 0.3s ease-in-out;
    display: block;
    object-fit: contain;
    width: 86px;
    height: 86px;
    opacity: 0.8;
    
    
}
.iconsInsideWrapper {
    transition: all 0.3s ease-in-out;
    position: absolute;
    z-index: 5;
    display: flex;
    gap: 12px;
    visibility: hidden;
    opacity: 0;
}
.oldImgInsideModal {
    width: 100%;
    border-radius: 2px;
    margin-top: 20px;
}
