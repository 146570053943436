.root {
    position: relative;
}

.pass {
    position: absolute;
    width: 25px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
