.table {
    width: 100%;
    margin: 30px 0;
    border-collapse: collapse;
    text-align: left;

    & thead {
        & tr {
            & th {
                padding: 20px 10px;
                font-weight: bold;
                width: 33%;
            }
        }
    }

    & tbody {
        & tr {
            & td {
                padding: 20px 10px;
                border-bottom: 1px solid #ccc;
            }
        }
    }
}
