.wrapperInput {
    width: 100%;
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 32px;
    padding: 0 11px;
    cursor: pointer;

    &:hover {
        border-color: #40a9ff;
    }
}

.activWrapperInput {
    box-shadow: 0 0 0 2px rgba(64, 169, 255, 0.2);
    border-color: #40a9ff;

}

.input {
    width: 100%;
    height: 100%;
    border: none;
}

.arrowDropDown {
    color: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-30%);
    transition: all 0.3s;

}



.selectItem {
    width: 100%;
    min-height: 30px;
    padding: 0 11px;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:hover {
        background-color: #ebebeb;
    }
}

// <div class="ant-select ant-select-single ant-select-show-arrow ant-select-show-search" style="width: 100%;">
.showList {}

.hideList {}



.selectWrapper {
    position: absolute;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    top: 34px;
    right: 0;
    width: 552px;
    z-index: 5;
    transition: all 1s;
    opacity: 1;
}

.noSelectWrapper {
    max-height: 0;
    transition: all 1s;

    opacity: 0;

}

.wrapper {
    width: 100%;
    position: relative;

}


/* Firefox */
.selectWrapper {
    scrollbar-width: thin;
    scrollbar-color: #575857 #DFE9EB;
}

/* Chrome, Edge and Safari */
.selectWrapper::-webkit-scrollbar {
    width: 10px;
    width: 8px;
}

.selectWrapper::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: #fff;
}

.selectWrapper::-webkit-scrollbar-track:active {
    background-color: #B8C0C2;
}

.selectWrapper::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #575757;
}



.selectWrapper::-webkit-scrollbar-thumb:active {
    background-color: #62A34B;
}