@import '../../../styles/config';

.footer {
    padding: 16px 20px;
    color: #fff;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
}

.exampleLink {
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    &:hover {
        text-decoration: underline;
    }
}

.borderline {
    border-bottom: 1px solid rgba(128, 128, 128, 0.274);
}
.publicEvents {
    font-size: 17px;
    font-weight: 400;
}

.title {
    word-break: break-all;
    font-size: 15px;
    font-weight: bolder;
    margin-bottom: 10px;
}
.description {
    word-break: break-all;
    line-height: 1.2;
}
.change {
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}
.item {
    display: flex;
    justify-content: space-between;
}
.icon {
    margin-left: 15px;
    color: rgba(255, 0, 0, 0.993);
    cursor: pointer;
    transition: all 0.3s ease-in;
    &:hover {
        transform: scale(1.3);
    }
}
.modal {
    width: 1000px;
    height: 500px;
}

.countEventsBlock {
    padding: 8px 20px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #000;
}

.rightEventCoutBlock {
    margin-left: 20px;

    & span {
        display: block;
    }
}

//
.zone {
    margin: 24px 0;
}
